<template>
  <div class="Agreements">
    <van-nav-bar
      title="注册协议"
      left-arrow
      fixed
      safe-area-inset-top
      placeholder
      @click-left="onClickLeft"
    />
    <div class="box" v-html="registerAgreement"></div>
  </div>
</template>

<script>
  import * as api from '@/utils/api'

  export default {
    data() {
      return {
        registerAgreement: ''
      }
    },
    mounted(){
      api.agreementApi("REGISTER").then(res => {
        this.registerAgreement = res.content;
      });
    },
    methods:{
      onClickLeft(){
        this.$router.push({
          name:'Agreements'
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
::v-deep{
  .van-nav-bar{
    height: 96px;
    .van-nav-bar__content{
      line-height: 96px;
      height: 96px;
      background: #39394d;
      .van-nav-bar__title{
        font-size: 36px;
        color: #F6D0AB;
      }
      .van-icon{
        font-size: 40px;
        color: #F6D0AB;
        right: 4px;
      }
    }
  }

}
.box{
  padding: 30px 30px 100px;
  font-size: 32px;
  color: #212121;
  text-align: center;
}
.title{
  text-indent:2em
}
</style>